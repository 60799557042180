<template>
  <div class="border rounded-2xl p-5 h-fit min-w-52 max-w-100">
    <SocialTypeInfo :social-type="postData.type" :size="SocialIconSize.LG" />
    <div
      class="flex flex-col"
      :class="{
        'flex-col-reverse mt-5': postData.type === ContentSocialType.INSTAGRAM,
      }"
    >
      <SocialPostDescription
        :description="postData.description"
        :is-customizable="props.isCustomizable"
        @update:error-state="postData.descriptionErrorState = $event"
      />
      <Carousel
        v-if="postData.socialFiles.length > 1"
        :variant="socialTypeCarousel[postData.type]"
        :files="carouselFiles"
        class="flex-grow"
        @load="rerenderStage"
      />
      <div v-else-if="isFileImage(singleFileUrl)" class="m-auto mt-5 relative">
        <ClientOnly v-if="showQuadrantsOverlay">
          <QuadrantsOverlay
            ref="quadrantsOverlay"
            :quadrants="props.quadrants"
            img-id="preview-image"
            :logo="postData.logo"
            @update:image-data="updateImage"
            @update:logo="updateLogo"
          />
        </ClientOnly>
        <img
          id="preview-image"
          :src="singleFileUrl"
          class="rounded-lg object-contain max-h-100 w-full h-full"
          alt="Image File"
          @load="rerenderStage"
        />
      </div>
      <div
        v-else-if="isFileVideo(singleFileUrl)"
        class="w-full rounded-lg aspect-video m-auto mt-5"
      >
        <video class="w-full h-full rounded-lg post-video" controls>
          <source :src="`${singleFileUrl}#t=0.001`" />
        </video>
      </div>
      <div
        v-else
        class="min-h-80 max-h-91 m-auto aspect-square mt-5 flex items-center justify-center bg-disabled text-surface rounded-lg"
      >
        <ImagePlaceholder />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImagePlaceholder from '~/assets/images/placeholders/image-placeholder.svg?component';
import { QuadrantsOverlay } from '~/pages/my-posts/_components/quadrants-overlay';
import {
  SocialTypeInfo,
  SocialIconSize,
} from '~/pages/_components/social-type-info';
import { Nullable } from '~/types/common';
import { LogoDto } from '~/composables/use-quadrants';
import { Carousel } from '~/components/carousel';
import { socialTypeCarousel } from '~/constants/social-posts';
import { PostModalContentForm } from '~/pages/my-posts/_components/post-modal-content/post-modal-content.types';
import { SocialPostDescription } from '~/pages/my-posts/_components/social-post-preview/components/social-post-description';
import { ContentSocialType } from '~/api/contents';

type Props = {
  isEditable?: boolean;
  isCustomizable?: boolean;
  quadrants?: Box[];
};

const props = defineProps<Props>();

const quadrantsOverlay =
  ref<Nullable<InstanceType<typeof QuadrantsOverlay>>>(null);
const showQuadrantsOverlay = computed(
  () => (props.isCustomizable || props.isEditable) && props.quadrants?.length,
);

const postData = defineModel<PostModalContentForm>('postData', {
  required: true,
});

const singleFileUrl = computed(() => postData.value.socialFiles[0]?.file?.url);

const rerenderStage = () => {
  if (quadrantsOverlay.value) {
    quadrantsOverlay.value.rerenderStage('preview-image');
  }
};

const updateImage = (imageData: string) => {
  postData.value.imageData = imageData;
};
const updateLogo = (logo: Partial<LogoDto>) => {
  postData.value.logo = {
    ...(postData.value.logo as LogoDto),
    ...logo,
  };
};

const carouselFiles = computed(() =>
  postData.value.socialFiles.map(({ file }) => ({
    id: file.id,
    src: file.url,
  })),
);
</script>
